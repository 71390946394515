<div class="glossary-container" >
  <div class="setting-row" >
    <div class="setting-column setting-column-source" *ngIf="!loadLang">
      <h3>Source Language</h3>
      
    </div>
    <div class="setting-column setting-column-target" *ngIf="!loadLang">
      <h3>Target Language </h3>
      
    </div>
    </div>
  <div class="setting-row" >
    <!-- <div class="setting-column">
      <h3>Source</h3>
      
    </div> -->
    <div class="setting-column" *ngIf="!loadLang">
      <app-language-selector
      [cities]="cities"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        (sourceLangChange)="sourceLang = $event"
        (targetLangChange)="targetLang = $event"
        [admin]="true"
        class="grid-row">
    </app-language-selector>
      
    </div>
    <!-- <div class="setting-column">
      <h3>Target language</h3>
      <input type="text" id="quantity" name="quantity"  class="effect-1">
    </div> -->

    
    <!-- <app-loading-spinner *ngIf="loadLang"></app-loading-spinner> -->
  </div>
  <div class="setting-row" >
  <div class="setting-column" *ngIf="!loadLang">
    <h3>Glossary Name</h3>
    <input type="text" id="quantity" name="quantity"  class="effect-1">
    <!-- <tag-input [ngModel]="selectedItems" [identifyBy]="'value'" [displayBy]="'display'"
               (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)" [onlyFromAutocomplete]="true">
      <tag-input-dropdown [showDropdownIfEmpty]="true" 
                          [autocompleteItems]="languages"></tag-input-dropdown>
    </tag-input> -->
    
  </div>
  <div class="setting-column" style="padding-left: 40px;" *ngIf="!loadLang">
    <h3>Description</h3>
    <input type="text" id="quantity" name="quantity"  class="effect-1">
    <!-- <tag-input [ngModel]="selectedItems" [identifyBy]="'value'" [displayBy]="'display'"
               (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)" [onlyFromAutocomplete]="true">
      <tag-input-dropdown [showDropdownIfEmpty]="true" 
                          [autocompleteItems]="languages"></tag-input-dropdown>
    </tag-input> -->
    
  </div>
  </div>
  
    <div class="setting-row ">
      <div class="setting-column" *ngIf="!loadLang">
        <p>
            Upload the glossary document
            <br />
            <i class="h12">The supported formats are .csv</i>
          </p>
          <input
            type="file"
            class="file-upload"
            
            #fileUpload multiple/>
      </div>

      <div class="setting-column" *ngIf="!loadLang">
        <scale-button style="margin-left: 10px" type="submit" ><app-loading-spinner></app-loading-spinner>Save</scale-button>
      </div>
      
    </div>
    <!-- <div class="setting-row setting-center" style="margin-top: 30px;">
      <scale-table>
        <table>
          
          <thead>
            <tr>
             
              <th
                
                id="title"
              >
                File name 
              </th>
              
              <th
                
              >
                Source language
              </th>
              <th
                
              >
              Traget language
              </th>
              <th
               
              >
                Created by 
              </th>
              <th
               
              >
                updated by 
              </th>
              <th
               
              >
                Created date 
              </th>
              <th
               
              >
                Updated date 
              </th>
              <th
                
              >
                Download
              </th>
            </tr>
          </thead>
          <tbody>
            ...
          </tbody>
        </table>
      </scale-table>
    </div> -->
    <app-loading-spinner *ngIf="loadLang"></app-loading-spinner>
  </div>