import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { UploadService } from '../shared/services/upload.service';
import { MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES, MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES, SupportedLanguages, invalidPattern,doumentExtensionSupport } from '../shared/data/common-data';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';
import { ErrorService } from '../shared/services/error-service.service';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-document-translate',
  templateUrl: './document-translate.component.html',
  styleUrls: ['./document-translate.component.css'],
})
export class DocumentTranslateComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload?: ElementRef;
  files: File[] = [];
  fileNames: string[] = [];
  supportedLanguages = SupportedLanguages;
  doumentExtensionSupport = doumentExtensionSupport;
  downloadLinks: { link: string, name: string }[] = [];
  isLoading: boolean = false;
  statusError: Boolean = false
  errorDetail: string =''

  cities: any = [];
  sourceLang = { locale: 'de', name: 'German' };
  targetLang = { locale: 'en', name: 'English' };
  translatedFileNames: string[] = [];

  translateEmbeddedImages = false;
  fileRequiredError: boolean = false;
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;
  filesNumberError: boolean = false;
  filesNameError : boolean = false;
  errorMessage: string = '';
  fileStatuses: { zip_file_name: string, status: string, zip_file_path: string, date: string, batch_id: string }[] = [];
  private statusInterval: any;

  constructor(
    private uploadService: UploadService,
    private authService: MsalService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService, private errorService: ErrorService,
    private httpClient: HttpClient,private languageService:LanguageService
  ) { }

  ngOnInit(): void {
    this.loadLanguages()
    this.fetchFileStatuses();
    this.statusInterval = setInterval(() => {
      this.fetchFileStatuses();
    }, 5000); // 15 seconds
  }

  ngOnDestroy(): void {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
  }

  compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.locale === obj2.locale : obj1 === obj2;
  }

  loadLanguages(){
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(response => {
      //this.loadLang = false;
      // Assuming the response contains an array of saved languages
      this.sourceLang = { locale: response[0].language[0].value, name: response[0].language[0].display }
      this.targetLang = { locale: response[0].language[1].value, name: response[0].language[1].display };
      this.cities = response[0].language.map((lang: any) => ({
        locale: lang.value,   // Locale (Language code)
        name: lang.display // Language name
      }));
      //this.dataRetentionPeriod = response.retentionPeriod; // Update the retention period from API
    }, error => {
      //this.loadLang = false;
      console.error('Error fetching saved languages:', error);
    });
  }

  onFileSelected(event: any) {
    this.files = Array.from(event.target.files) || [];
    this.fileNames = this.files.map(file => file.name);
    if (!this.validateFiles()) {
      return;
    }
  }

  onSubmit() {
    if (!this.validateFiles(true)) {
      return;
    }

    this.downloadLinks = [];
    const translateUrl = `${environment.documentTranslationUrl}`;
    const formData = new FormData();

    this.files.forEach(file => {
      formData.append('files', file, file.name);
    });

    formData.append('translation_request', JSON.stringify({
      source_language: this.sourceLang.locale,
      target_language: this.targetLang.locale,
      is_embedded_image: this.translateEmbeddedImages ? 'true' : 'false',
    }));

    this.uploadService.upload(translateUrl, formData).subscribe({
      next: (response: any) => {
        if (this.files.length === 1) {
          const file = this.files[0];
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const downloadLink = window.URL.createObjectURL(blob);
          const fileName = this.getFileNameWithLanguageCode(file.name, this.targetLang.locale);
          this.downloadLinks.push({ link: downloadLink, name: fileName });
          this.notificationService.showSuccess('');
        } else {
          this.clearFiles(this.fileUpload?.nativeElement);
          this.fetchFileStatuses();
          this.notificationService.showSuccess('Translation request has been accepted , Translated Files will be available in sometime');

        }
      },
      error: (error: any) => {
        if(error.detail){
          this.notificationService.showError('Translation failed :' + error.detail);
        }else{
          this.notificationService.showError('Translation failed :' + error.message);
        }
      }
    });
  }

  validateFiles(skipFileRequiredCheck: boolean = false): boolean {
    this.fileRequiredError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.filesNumberError = false;
    this.filesNameError = false;


    if (this.files.length === 0) {
      this.fileRequiredError = true;
      this.errorMessage = 'Please upload a document(s).'
      return false;
    }

    if (this.files.length > 10) {
      this.filesNumberError = true;
      this.errorMessage = 'File Limit Exceeded: You can only upload up to 10 files at a time. Please remove some files and try again.'
      return false;
    }

    let totalSize = 0;
    for (let file of this.files) {
      if (invalidPattern.test(file.name)) {
        this.filesNameError = true;
        this.errorMessage =  `The filename "${file.name}"  can't contain any of the following characters: \\ / : * ? , < > | #`
        return false;
      }

      if (!this.doumentExtensionSupport.includes(this.getFileExtension(file.name).toLowerCase())) {
        this.fileTypeError = true;
        this.errorMessage = 'Invalid file type. The supported formats are .ppt .pptx .doc .docx .xls .xlsx .pdf'
        return false;
      }
      totalSize += file.size;
      if (file.size > MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES || totalSize > MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES) {
        this.fileSizeError = true;
        this.errorMessage = 'File size exceeds the allowed limit. A single file can be up to 25 MB, and the total size of all files can be up to 100 MB.'
        return false;
      }
    }

    return true;
  }

  switchValues() {
    let temp = this.sourceLang;
    this.sourceLang = this.targetLang;
    this.targetLang = temp;
  }

  switchImageQuality() {
    this.translateEmbeddedImages = !this.translateEmbeddedImages;
  }

  clearFiles(fileUpload: HTMLInputElement) {
    this.files = [];
    this.fileNames = [];
    this.fileRequiredError = false;
    this.filesNumberError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    fileUpload.value = '';  // Clear the file input
    this.cdr.detectChanges();  // Trigger change detection to update the view
  }

  fetchFileStatuses() {
    this.uploadService.getStatus('documents').subscribe({
      next: (response: any) => {
        this.statusError = false
        this.fileStatuses = response;
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.statusError = true
        if(error.detail){
          //this.notificationService.showError('Failed to fetch file statuses: ' + error.detail);
          this.errorDetail = error.detail
        }
      }
    });
  }

  downloadfile(blob_folder_path: string, blob_name: string): void {
    this.uploadService.downloadFile(blob_folder_path, blob_name).subscribe({
      next: (blob: Blob) => {
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = blob_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        this.notificationService.showSuccess('Download successfull !')
      },
      error: (err) => {
        console.error('Download failed', err);
        this.notificationService.showError('Download Falied')
      }
    });
  }

  private getFileNameWithLanguageCode(fileName: string, langCode: string): string {
    const fileExtension = this.getFileExtension(fileName);
    const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${baseName}_${langCode}.${fileExtension}`;
  }

  private getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }
}
