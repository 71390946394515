<div class="grid-container">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="content">
    <scale-tab-nav size="large">
      <scale-tab-header slot="tab">
        <scale-icon-t-product-telekom-plan accessibility-title="telekom-plan" />
        Text
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <app-text-translate></app-text-translate>
      </scale-tab-panel>
      <scale-tab-header slot="tab">
        <scale-icon-user-file-file-collection
          accessibility-title="file-collection"
          selected />
        Document
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <app-document-translate></app-document-translate>
      </scale-tab-panel>
      <scale-tab-header slot="tab">
        <scale-icon-user-file-image-file
          accessibility-title="image-file"
          selected />
        Image
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <app-image-translate></app-image-translate>
      </scale-tab-panel>
      <scale-tab-header slot="tab">
        <scale-icon-user-file-html-file
          accessibility-title="html-file"
          selected />
        Website
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <app-website-translate></app-website-translate>
      </scale-tab-panel>
      <scale-tab-header slot="tab">
        <scale-icon-user-file-html-file
          accessibility-title="html-file"
          selected />
        Live Screen
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <app-live-translate></app-live-translate>
      </scale-tab-panel>
    </scale-tab-nav>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
