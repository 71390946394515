import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadingSpinnerService } from '../services/loading-spinner.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  /* intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  } */
  private readonly excludedUrls = [
    environment.statusapi,
    environment.downloadapi
  ];
  constructor(private loadingSpinnerService: LoadingSpinnerService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const shouldSkip = this.excludedUrls.some(url => req.url.startsWith(url));
    if (shouldSkip) {
      return next.handle(req);
    } else {
      this.loadingSpinnerService.setLoading(true);
      return next.handle(req).pipe(
        finalize(() => this.loadingSpinnerService.setLoading(false))
      );
    }
  }
}
