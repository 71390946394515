import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() {}

  public getImageErrorMessage(statusCode: number): string {
    switch (statusCode) {
      case 503:
        return 'Microsoft translation error: Could not process your document/image, please try again after some time';
      case 415:
        return 'Unsupported File Type: Unsupported file type. Please upload images only (e.g., JPG, PDF, DOC, PNG)';
      case 413:
        return 'File Size Limit Exceeded: File size limit exceeded. Please upload files smaller than 10 MB each or 100 MB consolidated';
      case 422:
        return 'Image Size: Image resolution too low. The uploaded image must have a minimum resolution of 20x20 pixels for processing. Please upload a higher resolution image and try again';
      case 409:
        return 'Duplicate File Names: Duplicate file names detected. Please ensure each file has a unique name and try again';
      case 400:
        return 'Invalid Request: The server cannot process the request due to a client error';
      case 500:
        return 'Internal Server Error: Something went wrong on the server. Please try again later';
      case 404:
        return 'Something went wrong ! upload failed . Please try again';
      default:
        return 'An unexpected error occurred. Please try again later';
    }
  }

  public getDocumentErrorMessage(statusCode: number): string {
    switch (statusCode) {
      case 503:
        return 'Microsoft translation error: Could not process your document/image, please try again after some time';
      case 415:
        return 'Unsupported File Type: Unsupported file type. Please upload images only (e.g., JPG, PDF, DOC, PNG)';
      case 413:
        return 'File Size Limit Exceeded: File size limit exceeded. Please upload files smaller than 10 MB each or 100 MB consolidated';
      case 422:
        return 'Image Size: Image resolution too low. The uploaded image must have a minimum resolution of 20x20 pixels for processing. Please upload a higher resolution image and try again';
      case 409:
        return 'Duplicate File Names: Duplicate file names detected. Please ensure each file has a unique name and try again';
      case 400:
        return 'Invalid Request: The server cannot process the request due to a client error';
      case 500:
        return 'Internal Server Error: Something went wrong on the server. Please try again later';
      case 404:
        return 'Something went wrong ! upload failed . Please try again';
      default:
        return 'An unexpected error occurred. Please try again later';
    }
  }
  public getTextErrorMessage(statusCode: number): string {
    switch (statusCode) {
      case 503:
        return 'Microsoft translation error: Could not process your text, please try again after some time';
      case 400:
        return 'Invalid Request: The server cannot process the request due to a client error';
      case 500:
        return 'Internal Server Error: Something went wrong on the server. Please try again later';
      case 404:
        return 'Something went wrong ! upload failed . Please try again';
      default:
        return 'An unexpected error occurred. Please try again later';
    }
  }
}
