import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ImageTranslateSubmit } from '../shared/models/request/types';
import { MAX_SINGLE_IMAGE_FILE_SIZE_BYTES, MAX_TOTAL_IMAGE_FILE_SIZE_BYTES, SupportedLanguages, imageExtensionSupport, invalidPattern } from '../shared/data/common-data';
import { UploadService } from '../shared/services/upload.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/services/notification.service';
import { ErrorService } from '../shared/services/error-service.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-image-translate',
  templateUrl: './image-translate.component.html',
  styleUrls: ['./image-translate.component.css'],
})
export class ImageTranslateComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload?: ElementRef;
  files: File[] = [];
  fileNames: string[] = [];
  supportedLanguages = SupportedLanguages;
  imageExtensionSupport = imageExtensionSupport;
  preserveMetadataOn = false;
  downloadLink: string | null = null;
  showModal: boolean = false;
  previewImageUrl: string | null = null;
  statusError: Boolean = false
  errorDetail: string =''

  cities: any = [];
  sourceLang = { locale: 'de', name: 'German' };
  targetLang = { locale: 'en', name: 'English' };
  imgSrc: any;
  translatedFileName: string = '';

  fileRequiredError: boolean = false;
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;
  filesNumberError: boolean = false;
  filesNameError : boolean = false;

  fileStatuses: { zip_file_name: string, status: string, zip_file_path: string, date: string, batch_id: string }[] = [];
  private statusInterval: any;
  isLoading: boolean = false;
  errorMessage: string = '';

  constructor(
    private uploadService: UploadService,
    private cdr: ChangeDetectorRef,
    private authService: MsalService,
    private notificationService: NotificationService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.cities = SupportedLanguages;
    this.fetchFileStatuses();
    this.statusInterval = setInterval(() => {
      this.fetchFileStatuses();
    }, 5000); // 15 seconds
  }

  ngOnDestroy(): void {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
  }

  imgTranslateForm: ImageTranslateSubmit = {
    sourceLanguage: '',
    targetLanguage: '',
    image: '',
  };

  compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.locale === obj2.locale : obj1 === obj2;
  }

  onChange(eventData: any) {
    console.log('CHANGE EVENT DATA:', eventData);
  }

  openModal(imageUrl: string) {
    this.previewImageUrl = imageUrl;
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.previewImageUrl = null;
  }

  switchValues() {
    let temp = this.sourceLang;
    this.sourceLang = this.targetLang;
    this.targetLang = temp;
  }

  onFilesSelected(event: any) {
    this.files = Array.from(event.target.files);
    this.fileNames = this.files.map(file => file.name);
    if (!this.validateFiles()) {
      return;
    }
  }

  onSubmit() {

    if (!this.validateFiles()) {
      return;
    }

    this.downloadLink = null;
    const formData = new FormData();

    this.files.forEach((file) => {
      this.translatedFileName = `${file.name.split('.').slice(0, -1).join('.')}_${this.targetLang.locale}.${this.getFileExtension(file.name)}`;
      this.files.length === 1 ? formData.append('file', file, file.name) : formData.append('files', file, file.name);
    });

    let translateUrl = this.files.length === 1 ? environment.imageSingleFileUrl : environment.imageBatchFileUrl;
    translateUrl += `?source_lang=${this.sourceLang.locale}&target_lang=${this.targetLang.locale}`;
    translateUrl += this.preserveMetadataOn ? '&preserve_metadata_on=WORD' : '&preserve_metadata_on=LINE';

    this.uploadService.upload(translateUrl, formData).subscribe({
      next: (response: any) => {
        if (this.files.length === 1) {
          const file = this.files[0];
          const blob = new Blob([response], { type: file.type });
          this.downloadLink = window.URL.createObjectURL(blob);
        } else {
          this.clearFiles(this.fileUpload?.nativeElement);
          this.fetchFileStatuses();
          this.notificationService.showSuccess('Translation request has been accepted, Translated Files will be available in some time');
        }
      },
      error: (error: any) => {
        if(error.detail){
          this.notificationService.showError('Translation failed :' + error.detail);
        }else{
          this.notificationService.showError('Translation failed :' + error.message);
        }
      }
    });
  }

  validateFiles(): boolean {
    this.fileRequiredError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.filesNumberError = false;
    this.filesNameError = false;

    if (this.files.length === 0) {
      this.fileRequiredError = true;
      this.errorMessage = 'Please upload a document(s).'
      return false;
    }

    if (this.files.length > 10) {
      this.filesNumberError = true;
      this.errorMessage = 'File Limit Exceeded: You can only upload up to 10 files at a time. Please remove some files and try again.'
      return false;
    }

    let totalSize = 0;
    for (let file of this.files) {
      if (invalidPattern.test(file.name)) {
        this.filesNameError = true;
        this.errorMessage =  `The filename "${file.name}"  can't contain any of the following characters:\\ / : * ? , < > | #`
        return false;
      }
      const fileExtension = this.getFileExtension(file.name).toLowerCase();
      if (!this.imageExtensionSupport.includes(fileExtension)) {
        this.fileTypeError = true;
        this.errorMessage = 'Invalid file type. The supported formats are .png, .jpg, .jpeg, .tiff'
        return false;
      }
      totalSize += file.size;
      if (file.size > MAX_SINGLE_IMAGE_FILE_SIZE_BYTES) {
        this.fileSizeError = true;
        this.errorMessage = 'Image size exceeds the allowed limit. A single image can be up to 10 MB, and the total size of all images can be up to 100 MB.'
        return false;
      }
    }

    if (totalSize > MAX_TOTAL_IMAGE_FILE_SIZE_BYTES) {
      this.fileSizeError = true;
      this.errorMessage = 'Image size exceeds the allowed limit. A single image can be up to 10 MB, and the total size of all images can be up to 100 MB.'
      return false;
    }

    return true;
  }

  clearErrors() {
    this.fileRequiredError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.filesNumberError = false;
  }

  switchImageQuality() {
    this.preserveMetadataOn = !this.preserveMetadataOn;
  }

  clearFiles(fileUpload: HTMLInputElement) {
    this.files = [];
    this.fileNames = [];
    this.clearErrors();
    fileUpload.value = '';  // Clear the file input
    this.cdr.detectChanges();  // Trigger change detection to update the view
  }

  fetchFileStatuses() {
    const o_id = this.authService.instance.getActiveAccount()?.idTokenClaims?.oid;
    if (o_id) {
      this.uploadService.getStatus('images').subscribe({
        next: (response: any) => {
          this.statusError = false
          this.fileStatuses = response;
          this.cdr.detectChanges();
        },
        error: (error: any) => {
          this.statusError = true
          if(error.detail){
            // this.notificationService.showError('Failed to fetch image statuses: ' + error.detail);
            this.errorDetail = error.detail
          }
        }
      });
    }
  }

  downloadfile(blob_folder_path: string, blob_name: string): void {
    this.isLoading = true;
    this.uploadService.downloadFile(blob_folder_path, blob_name).subscribe({
      next: (blob: Blob) => {
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = blob_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
        this.notificationService.showSuccess('Download successful!');
      },
      error: (err) => {
        console.error('Download failed', err);
        this.notificationService.showError('Download Failed');
      }
    });
  }

  private getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }
}
