import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  showDataPrivacy = false
  showTermsAndConditionsDe = false
  showTermsAndConditionsEn = false

  loadDataPrivacy(){
   this.showDataPrivacy = !this.showDataPrivacy
  }

  loadTermsAndConditions (lang: string) {
    if(lang == 'en'){
      this.showTermsAndConditionsEn = !this.showTermsAndConditionsEn
    }else{
      this.showTermsAndConditionsDe = !this.showTermsAndConditionsDe
    }
  }
  
  closeModal(){
    this.showTermsAndConditionsDe = false
    this.showTermsAndConditionsEn = false
  }
}
