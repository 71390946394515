<div class="translator-card">
  <div class="img-grid-container">
    <form #imgTranslateForm="ngForm" (ngSubmit)="onSubmit()">
      <app-language-selector
        [cities]="cities"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        (sourceLangChange)="sourceLang = $event"
        (targetLangChange)="targetLang = $event"
        (switchValues)="switchValues()" class="grid-row">
      </app-language-selector>
      <div class="grid-row">
        <div class="grid-column">
          <p>
            Upload the image(s) to be translated.
            <br />
            <i class="h12">The supported formats are .png .jpg .jpeg .tiff .bmp</i>
          </p>
          <input
            type="file"
            class="file-upload"
            (change)="onFilesSelected($event)"
            #fileUpload multiple />
          <scale-icon-action-remove accessibility-title="remove" selected class="remove" size="17" *ngIf="fileUpload.value" (click)="clearFiles(fileUpload)" />
          <div *ngIf="fileRequiredError" class="error-message">{{errorMessage}}</div>
          <div *ngIf="fileTypeError" class="error-message">{{errorMessage}}</div>
          <div *ngIf="fileSizeError" class="error-message">
            {{errorMessage}}
          </div>
          <div *ngIf="filesNumberError" class="error-message">
            {{errorMessage}}
          </div>
          <div *ngIf="filesNameError" class="error-message">
            {{errorMessage}}
          </div>
        </div>
        <div class="grid-column"></div>
        <div class="grid-column">
          <div class="container">
            <table>
              <tr>
                <p class="element">Retain image look & feel?</p>
                <label class="element-label">No</label>
                <scale-switch size="small" style="float: left; margin-top: 15px; font-weight: 500;" label="Yes" (change)="switchImageQuality()" [checked]="preserveMetadataOn"></scale-switch>
              </tr>
              <tr class="element">
                <scale-button style="margin-left: 10px" type="submit"><app-loading-spinner></app-loading-spinner>Translate</scale-button>
                <a *ngIf="downloadLink" class="preview" (click)="openModal(downloadLink)">Preview</a>
                <a *ngIf="downloadLink" [href]="downloadLink" [download]="translatedFileName" class="download">Download</a>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="grid-row-table">
        <!-- <div class="error-msg centered-table" *ngIf="!statusError">
          
        </div> -->
        <div >
          <scale-table *ngIf="fileStatuses.length>0 || statusError" class="centered-table scrollable-table">
          <table>
            <thead>
              <tr>
                <th>Image Batch ID</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody *ngIf="!statusError">
              <tr *ngFor="let file of fileStatuses">
              <td>{{ file.batch_id }}</td>
              <td>
                  <scale-tag *ngIf="file.status === 'failed'" size="small" color="red">{{file.status}}</scale-tag>
                  <scale-tag *ngIf="file.status === 'success'" size="small" color="green">{{file.status}}</scale-tag>
                  <scale-tag *ngIf="file.status !== 'failed' && file.status !== 'success'" size="small">{{file.status}}</scale-tag>
              </td>
              <td>
                <scale-icon-action-download-from-cloud 
                  accessibility-title="download" 
                  [ngClass]="{'magenta-icon': file.status === 'success'}"
                  [attr.disabled]="file.status !== 'success' ? true : null"
                  (click)="file.status === 'success' && downloadfile(file.zip_file_path, file.zip_file_name)">
                </scale-icon-action-download-from-cloud>
              </td>
              </tr>
            </tbody>
            <tbody *ngIf="statusError">
              <tr>
                <td colspan="4" class="error-msg">
                  <scale-icon-action-circle-close accessibility-title="circle-close" size="12"/>
                  Failed to retrieve the status of the batch image <span *ngIf="errorDetail">: {{ errorDetail }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </scale-table>
        </div>
      </div>
    </form>
  </div>
</div>

<scale-modal heading="Image Preview" id="modal" [opened]="showModal" slot="action" (click)="closeModal()">
  <img *ngIf="previewImageUrl" [src]="previewImageUrl" alt="Preview Image">
  <p *ngIf="!previewImageUrl">No image preview available.</p>
  <scale-button slot="action" variant="secondary" (click)="closeModal()">Close</scale-button>
</scale-modal>
