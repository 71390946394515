import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  // add authorization header with basic auth credentials if available
  /* constructor(private authService: AuthenticationService) {}
    
    intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
            const currentUser = this.authService.currentUserValue;
            if (currentUser && currentUser.authdata) {
                req = req.clone({
                    setHeaders: { 
                        Authorization: `Bearer ${currentUser.authdata}`
                    }
                });
            }
            return next.handle(req)
        } */
  /*  constructor(private authService: MsalService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.instance.getActiveAccount()?.idToken;
    debugger;
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return next.handle(request);
  } */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}
