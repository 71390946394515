import { Component } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {
  activeComponent: string = 'languages';  
  setActiveComponent(component: string) {
    this.activeComponent = component;
  }

}
