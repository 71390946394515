export const SupportedLanguages = [
  { "locale": "af", "name": "Afrikaans" },
  { "locale": "ar", "name": "Arabic" },
  { "locale": "bg", "name": "Bulgarian" },
  { "locale": "bn", "name": "Bengali" },
  { "locale": "bs", "name": "Bosnian" },
  { "locale": "ca", "name": "Catalan" },
  { "locale": "cs", "name": "Czech" },
  { "locale": "cy", "name": "Welsh" },
  { "locale": "da", "name": "Danish" },
  { "locale": "de", "name": "German" },
  { "locale": "el", "name": "Greek" },
  { "locale": "en", "name": "English" },
  { "locale": "es", "name": "Spanish" },
  { "locale": "et", "name": "Estonian" },
  { "locale": "fa", "name": "Persian" },
  { "locale": "fi", "name": "Finnish" },
  { "locale": "fil", "name": "Filipino" },
  { "locale": "fr", "name": "French" },
  { "locale": "he", "name": "Hebrew" },
  { "locale": "hi", "name": "Hindi" },
  { "locale": "hr", "name": "Croatian" },
  { "locale": "hu", "name": "Hungarian" },
  { "locale": "id", "name": "Indonesian" },
  { "locale": "is", "name": "Icelandic" },
  { "locale": "it", "name": "Italian" },
  { "locale": "ja", "name": "Japanese" },
  { "locale": "ko", "name": "Korean" },
  { "locale": "lt", "name": "Lithuanian" },
  { "locale": "lv", "name": "Latvian" },
  { "locale": "mk", "name": "Macedonian" },
  { "locale": "ml", "name": "Malayalam" },
  { "locale": "mr", "name": "Marathi" },
  { "locale": "ms", "name": "Malay" },
  { "locale": "mt", "name": "Maltese" },
  { "locale": "nl", "name": "Dutch" },
  { "locale": "no", "name": "Norwegian" },
  { "locale": "pl", "name": "Polish" },
  { "locale": "pt", "name": "Portuguese" },
  { "locale": "ro", "name": "Romanian" },
  { "locale": "ru", "name": "Russian" },
  { "locale": "sk", "name": "Slovak" },
  { "locale": "sl", "name": "Slovenian" },
  { "locale": "sr", "name": "Serbian" },
  { "locale": "sv", "name": "Swedish" },
  { "locale": "sw", "name": "Swahili" },
  { "locale": "ta", "name": "Tamil" },
  { "locale": "te", "name": "Telugu" },
  { "locale": "th", "name": "Thai" },
  { "locale": "tr", "name": "Turkish" },
  { "locale": "uk", "name": "Ukrainian" },
  { "locale": "ur", "name": "Urdu" },
  { "locale": "vi", "name": "Vietkse" },
  { "locale": "zh-Hans", "name": "Chinese (Simplified)" },
  { "locale": "zh-Hant", "name": "Chinese (Traditional)" }
]


export const doumentExtensionSupport = [
  'ppt',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'pdf',
  'pptx',
];

export const imageExtensionSupport = ['png', 'jpg', 'jpeg', 'tiff', 'bmp'];

export const MAX_SINGLE_DOCUMENT_FILE_SIZE_MB = 25; // 10 MB
export const MAX_TOTAL_DOCUMENT_FILE_SIZE_MB = 100; // 100 MB

export const MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES =
  MAX_SINGLE_DOCUMENT_FILE_SIZE_MB * 1024 * 1024;
export const MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES =
  MAX_TOTAL_DOCUMENT_FILE_SIZE_MB * 1024 * 1024;

export const MAX_SINGLE_IMAGE_FILE_SIZE_MB = 10; // 10 MB
export const MAX_TOTAL_IMAGE_FILE_SIZE_MB = 100; // 100 MB

export const MAX_SINGLE_IMAGE_FILE_SIZE_BYTES =
  MAX_SINGLE_IMAGE_FILE_SIZE_MB * 1024 * 1024;
export const MAX_TOTAL_IMAGE_FILE_SIZE_BYTES =
  MAX_TOTAL_IMAGE_FILE_SIZE_MB * 1024 * 1024;
export const invalidPattern =
  /[\\#,/?\u0000-\u001F\u0080\u0082-\u0089\u008A-\u008C\u008E\u0091-\u009A\u009B-\u009C\u009E-\u009F\uFDD1-\uFDEF\uFFF0-\uFFFF]/;
