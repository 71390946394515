<div class="grid-container">
  <div class="header">
    <scale-telekom-header
      app-name=""
      app-name-link=""
      meta-nav-aria-label="Meta navigation section"
      meta-nav-external-aria-label="External navigation section"
      lang-switcher-aria-label="Language switcher section"
      main-nav-aria-label="Main navigation section">
    </scale-telekom-header>
  </div>
  <div class="content">
    <div class="col-s-12 offset-s-0 col-m-12 col-l-6">
      <div class="cu-login__layer">
        <h1 class="cu-login__headline">
          Welcome to<br />
          <span class="cu-login__headline-ultra">Translation Suite</span>
        </h1>
        <fieldset class="cu-login-form__fieldset">
          <form
            #loginForm="ngForm"
            (ngSubmit)="loginForm.valid && loginRedirect()"
            class="cu-login-form">
            <!-- <div class="form-input-set">
            <div class="form-group">
              <label for="email" class="cu-login-form__label"
                >Email Address</label
              >
              <scale-text-field
                id="email"
                name="email"
                type="email"
                inputModeType="email"
                label=""
                class="form-control"
                placeholder="Enter your E-mail Address"
                required
                email
                ngDefaultControl
                [(ngModel)]="emailValue"
                tabindex="1"
                #email="ngModel"></scale-text-field>
                <div *ngIf="(email.touched || loginForm.submitted ) && email.errors" class="invalid-feedback">
                  <div *ngIf="email.errors['required']">Email is required</div>
                  <div *ngIf="email.errors['email']">Email is invalid</div>
              </div>
            </div>
          </div> -->
            <!-- <div class="form-input-set">
            <div class="form-group">
              <a
                href="/account/passwort-vergessen/"
                id="tx_dtagloginregister_forgotpassword"
                class="pull-right cu-login-form__anchor-forgot"
                tabindex="4">
                Password forgotten?
              </a>
              <label for="password" class="cu-login-form__label"
                >Password</label
              >
              <scale-text-field
                id="password"
                name="password"
                type="password"
                label=""
                class="form-control"
                placeholder="Enter your password"
                required
                ngDefaultControl
                [(ngModel)]="passwordValue"
                tabindex="2"
                minlength="6"
                maxlength="20"
                #password="ngModel"></scale-text-field>
                <div *ngIf="(password.touched || loginForm.submitted) && password.errors" class="invalid-feedback">
                  <div *ngIf="password.errors['required']">Password is required</div>
                  <div *ngIf="password.errors['minlength']">
                    Password must be at least 6 characters
                  </div>
                  <div *ngIf="password.errors['maxlength']">
                    Pasword must not exceed 20 characters
                  </div>
                </div>
            </div>
          </div> -->
            <div>
              <scale-button type="submit"
                ><app-loading-spinner></app-loading-spinner>Login</scale-button
              >
            </div>
          </form>
        </fieldset>
        <!--   <p
          class="cu-login-form__bottom-line"
          id="tx_dtagloginregister_calltoregister">
          No Translation Suite account yet?<br />
          <a tabindex="5" href="/account/register/"> Register now </a>
        </p> -->
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
