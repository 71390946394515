import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {

  private statusUrl = environment.statusapi;
  private downloadUrl = environment.downloadapi;
  constructor(private httpClient: HttpClient) {}


  getStatus(translation_type: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.post<any>(this.statusUrl, {translation_type }, { headers });
  }

  downloadFile(zip_file_path: string, zip_file_name: string): Observable<Blob> {
    const body = { "blob_name": zip_file_name, "blob_folder_path": zip_file_path };
    return this.httpClient.post<Blob>(this.downloadUrl, body, {
      responseType: 'blob' as 'json'
    });
  }
  


  public upload(translateUrl: string, formData: FormData): Observable<any> {
    return this.httpClient.post(translateUrl, formData, {
      responseType: 'blob',
    }).pipe(
      map((response: any) => response), // Pass through the response
      catchError((error: HttpErrorResponse) => {
        let errorMsg = 'An unknown error occurred!';

        // Extract the error message from the error response body
        if (error.error instanceof Blob && error.error.type === 'application/json') {
          // Convert Blob to JSON
          return new Observable(observer => {
            const reader = new FileReader();
            reader.onloadend = (e) => {
              const errorJson = JSON.parse(reader.result as string);
              observer.error(errorJson);
              observer.complete();
            };
            reader.readAsText(error.error);
          });
        } else {
          if (error.error && typeof error.error === 'string') {
            errorMsg = error.error;
          } else if (error.message) {
            errorMsg = error.message;
          }
        }
        
        return throwError(errorMsg);
      })
    );
  }
}
