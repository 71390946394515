import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/request/types';
import { Router } from '@angular/router';
import {
  MsalService,
} from '@azure/msal-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  userRole!: [string];
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private msalService: MsalService,
    private notificationService : NotificationService
  ) {
    this.currentUserSubject = new BehaviorSubject<User | null>(
      this.getUserFromLocalStorage()
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // this.setUserRole();
    this.init();
  }
  async init() {
    const token = await this.acquireToken(); // Await token acquisition
    if (token) {
      this.decodeTokenAndSetRole(token)
    }
  }

  async acquireToken(retry: boolean = true): Promise<string | null> {
    const account = this.msalService.instance.getActiveAccount();
    
    if (account) {
      try {
        const result = await this.msalService.instance.acquireTokenSilent({
          scopes: ['api://0dfe3b06-90d2-4aaf-b317-695d06af6e66/.default'],
          account: account,
        });
        
        if (result && result.accessToken) {
          this.decodeTokenAndSetRole(result.accessToken);
          return result.accessToken; // Return the token if successful
        }
      } catch (error) {
        this.notificationService.showError('Silent token acquisition failed. Attempting again...');
  
        if (retry) {
          // Retry the silent token acquisition
          return await this.acquireToken(false); // Try again with no retry allowed
        } else {
          // Optionally fall back to acquireTokenPopup for interactive login
          try {
            const result = await this.msalService.instance.acquireTokenPopup({
              scopes: ['api://0dfe3b06-90d2-4aaf-b317-695d06af6e66/.default'],
              account: account,
            });
  
            if (result && result.accessToken) {
              this.decodeTokenAndSetRole(result.accessToken);
              return result.accessToken; // Return the token after fallback
            }
          } catch (popupError) {
            this.notificationService.showError('Interactive token acquisition failed.');
            return null;
          }
        }
      }
    }
    return null; // Return null if acquisition fails
  }
  
  decodeTokenAndSetRole(token: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);  // Decode the JWT token
    if (decodedToken && decodedToken['roles']) {
      this.userRole = decodedToken['roles']; // Assuming roles is an array
    } else {
      this.userRole = ['User']; // Default role if no roles claim is found
    }
  }

  hasRole(role: string): boolean {
    return this.userRole.includes(role);
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string): Observable<User> {
    const body = { username, password }; // Construct the request body object
    return this.http.post<any>(environment.authUrl, body).pipe(
      map(user => {
        // Store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.router.navigate(['']);
        return user;
      })
    );
  }

  logout() {
    // Remove user from local storage and update currentUserSubject
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['login']);
  }

  isAuthenticated(): boolean {
    // Check if user is logged in
    return this.currentUserValue !== null;
  }

  private getUserFromLocalStorage(): User | null {
    const user = localStorage.getItem('currentUser');
    return user ? JSON.parse(user) : null;
  }
}
