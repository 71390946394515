import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingSpinnerService } from '../shared/services/loading-spinner.service';
import { SupportedLanguages } from '../shared/data/common-data';
import { environment } from 'src/environments/environment';
import { TextTranslationResponse } from '../shared/models/response/types';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../shared/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-text-translate',
  templateUrl: './text-translate.component.html',
  styleUrls: ['./text-translate.component.css'],
})
export class TextTranslateComponent implements OnInit {
  @ViewChild('input') inputElement: any;
  @ViewChild('output') outputElement: any;
  @ViewChild('textTranslateForm') textTranslateForm!: NgForm;

  translatedText: string = '';
  response$: Observable<string> | undefined;

  cities: any = [];
  inputValue: string = '';
  outputValue: string = '';
  textareaDirty: boolean = false;
  sourceLang = { locale: 'de', name: 'German' };
  targetLang = { locale: 'en', name: 'English' };

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService, private NotificationService: NotificationService,private router: Router
  ) {}

  ngOnInit(): void {
    this.cities = SupportedLanguages;
  }

  compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.locale === obj2.locale : obj1 === obj2;
  }

  onChange(eventData: any) {
    console.log('CHANGE EVENT DATA:', eventData);
  }

  onReset() {
    this.inputValue = '';
    this.translatedText = '';
  }

  onSubmit() {
    if (!this.inputValue) {
      this.textareaDirty = true;
      return;
    } else {
      const body = {
        target_languages: [this.targetLang.locale],
        source_language: this.sourceLang.locale,
        text_to_translate: this.textTranslateForm.value.input
      };

      this.httpClient
        .post<TextTranslationResponse>(environment.textTranslationUrl, body)
        .subscribe({
          next: (result: any) => {
            this.translatedText = result[0].text;
          },
          error: (err: any) => {
            
            if(err.status==401){
              this.NotificationService.showError('Please login again '+err.error.detail);
              this.router.navigate(['/']);
            }else{
              this.NotificationService.showError(err.error.detail);
            }
          }
        });
    }
  }

  onSelected(event: any) {}

  switchValues() {
    let temp = this.sourceLang;
    this.sourceLang = this.targetLang;
    this.targetLang = temp;
  }

  copyToClipBoard() {
    const copyText = document.getElementsByClassName('copy-text')[0];
    copyText.classList.add('active');
    const copied = copyText.querySelector('scale-textarea#output.form-control.hydrated') as HTMLInputElement;
    navigator.clipboard.writeText(copied.value);
    copyText.classList.add('active');
    setTimeout(function () {
      copyText.classList.remove('active');
    }, 2500);
  }
}
