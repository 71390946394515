export const environment = {
  production: false,
  authUrl: 'https://dev.translate.dtse.telekom.net/text/authenticate',
  textTranslationUrl: 'https://dev.translate.dtse.telekom.net/text',
  documentTranslationUrl: 'https://dev.translate.dtse.telekom.net/document',
  imageSingleFileUrl:
    'https://dev.translate.dtse.telekom.net/image/single_file',
  imageBatchFileUrl: 'https://dev.translate.dtse.telekom.net/image/batch_file',
  imageHeartbeatUrl: 'https://dev.translate.dtse.telekom.net/image',
  statusapi:'https://dev.translate.dtse.telekom.net/utility/status',
  downloadapi:'https://dev.translate.dtse.telekom.net/utility/download',
  name: 'local',
  msalConfig: {
    auth: {
      clientId: '38420e0d-4679-4fa7-b676-863f7e288548', //c390864a-85a5-41ca-8973-373e52363c3d',
      //'e0b209a2-e80c-4876-8704-755cf6adc23a', //'b04c5f70-ea91-4727-8a66-869b23c70dca',
      authority:
        'https://login.microsoftonline.com/628242bd-7e70-4aa9-8ee1-72586b4540fe',
      //90725bd0-98a6-4fb4-be90-3095fdcd15e0',
      // 90725bd0-98a6-4fb4-be90-3095fdcd15e0', //628242bd-7e70-4aa9-8ee1-72586b4540fe',
      redirectUri: 'https://dev.translate.dtse.telekom.net/translate',
    },
    apiConfig: {
      scopes: ['api://0dfe3b06-90d2-4aaf-b317-695d06af6e66/Files.Read'], // ['api://523cc4f6-5cc5-4084-b4a7-2687087dae95/Files.Read'], //['user.read'],
      uri: /* 'https://graph.microsoft.com/v1.0/me' */ 'https://dev.translate.dtse.telekom.net',
    },
  },
};
