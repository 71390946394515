import { Component, AfterViewInit, Inject } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements AfterViewInit {
  constructor(
    private authService: AuthenticationService,
    private msalAuthService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}
  ngAfterViewInit(): void {}
  isMenuOpen = false;

  openMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  logout() {
    //this.authService.logout()

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalAuthService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalAuthService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  }
}
