<div class="translator-card">
  <h2>How to Install and Use the Translation Extension in Google Chrome:</h2>
  <div class="instruction-container">
    <ol>
      <li>Open Chrome Browser: Launch Google Chrome on your computer.</li>
      <li>
        Navigate to Extensions: Click on the three-dot menu icon in the
        top-right corner of the Chrome window. From the dropdown menu, go to
        "More tools" and then select "Extensions."
      </li>
      <li>
        Enable Developer Mode: In the Extensions page, you should see a toggle
        switch labeled "Developer mode" at the top-right corner of the page.
        Turn this switch on to enable Developer Mode.
      </li>
      <li>
        Load Unzipped Extension: After enabling Developer Mode, you'll see
        additional options appear. Click on the "Load unpacked" button. A file
        dialog will open.
      </li>
      <li>
        Select Extension Files: Navigate to the directory where your extension
        files are located (make sure they are unzipped). Select the folder
        containing your extension's files and click "Select" or "Open."
      </li>
      <li>
        Confirm Extension Installation: Your extension should now be loaded into
        Chrome. You'll see its icon appear in the Chrome toolbar next to the
        address bar.
      </li>
      <li>
        Visit Website to Translate: Go to the website that you want to translate
        in Chrome.
      </li>
      <li>
        Activate Extension: Click on the icon of your extension in the Chrome
        toolbar. This should open a popup or a dropdown menu with options
        related to your extension.
      </li>
      <li>
        Translate Website: In the extension popup or dropdown menu, click on the
        button labeled "Translate." This should initiate the translation process
        for the current website.
      </li>
      <li>
        View Translated Website: Once the translation process is complete, you
        should see the website content translated into the desired language.
      </li>
    </ol>
  </div>
  <!-- Download Button -->
  <!-- <a class="download-button" href="link_to_your_extension_file.zip" download>Download Extension</a> -->
</div>
