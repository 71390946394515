export const SupportedLanguages = [
  { locale: 'en', name: 'English' },
  { locale: 'de', name: 'German' },
  { locale: 'sk', name: 'Slovak' },
  { locale: 'cs', name: 'Czech' },
  { locale: 'ro', name: 'Romanian' },
  { locale: 'hu', name: 'Hungarian' },
  { locale: 'el', name: 'Greek' },
  { locale: 'pl', name: 'Polish' },
  { locale: 'hr', name: 'Croatian' },
  { locale: 'mk', name: 'Macedonian' },
  { locale: 'es', name: 'Spanish' },
  { locale: 'nl', name: 'Dutch' },
  { locale: 'fr', name: 'French' }
];

export const doumentExtensionSupport = [
  'ppt',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'pdf',
  'pptx',
];

export const imageExtensionSupport = ['png', 'jpg', 'jpeg', 'tiff', 'bmp'];

export const MAX_SINGLE_DOCUMENT_FILE_SIZE_MB = 25; // 10 MB
export const MAX_TOTAL_DOCUMENT_FILE_SIZE_MB = 100; // 100 MB

export const MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES =
  MAX_SINGLE_DOCUMENT_FILE_SIZE_MB * 1024 * 1024;
export const MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES =
  MAX_TOTAL_DOCUMENT_FILE_SIZE_MB * 1024 * 1024;

export const MAX_SINGLE_IMAGE_FILE_SIZE_MB = 10; // 10 MB
export const MAX_TOTAL_IMAGE_FILE_SIZE_MB = 100; // 100 MB

export const MAX_SINGLE_IMAGE_FILE_SIZE_BYTES =
  MAX_SINGLE_IMAGE_FILE_SIZE_MB * 1024 * 1024;
export const MAX_TOTAL_IMAGE_FILE_SIZE_BYTES =
  MAX_TOTAL_IMAGE_FILE_SIZE_MB * 1024 * 1024;
export const invalidPattern =
  /[\\#,/?\u0000-\u001F\u0080\u0082-\u0089\u008A-\u008C\u008E\u0091-\u009A\u009B-\u009C\u009E-\u009F\uFDD1-\uFDEF\uFFF0-\uFFFF]/;
