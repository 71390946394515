import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  errorMessage: string = '';
  successMessage: string = '';

  constructor(private notificationService: NotificationService,private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.notificationService.errorMessage$.subscribe(message => {
      this.errorMessage = message;
      if (message) {
        this.showNotification('error');
      }
      this.cdr.detectChanges();
    });

    this.notificationService.successMessage$.subscribe(message => {
      this.successMessage = message;
      if (message) {
        this.showNotification('success');
      }
      this.cdr.detectChanges();
    });
  }

  private showNotification(type: 'error' | 'success') {
    const element = document.getElementById(`${type}-message`);
    if (element) {
      element.style.display = 'block';
      setTimeout(() => {
        element.style.display = 'none';
      }, 5000); // Hide after 3 seconds
    } 
  }
  
  
}
