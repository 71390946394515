import { Component, OnInit } from '@angular/core';
import { SupportedLanguages } from '../shared/data/common-data';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {
  cities: any = [];
  sourceLang = { locale: '', name: '' };
  targetLang = { locale: '', name: '' };
  loadLang = false;
  constructor(private languageService:LanguageService
  ) { }
  ngOnInit(): void {
    this.loadLanguages()
  }


  loadLanguages(){
    this.loadLang = true;
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(response => {
      this.loadLang = false;
      // Assuming the response contains an array of saved languages
      this.sourceLang = { locale: response[0].language[0].value, name: response[0].language[0].display }
      this.targetLang = { locale: response[0].language[1].value, name: response[0].language[1].display };
      this.cities = response[0].language.map((lang: any) => ({
        locale: lang.value,   // Locale (Language code)
        name: lang.display // Language name
      }));
      //this.dataRetentionPeriod = response.retentionPeriod; // Update the retention period from API
    }, error => {
      this.loadLang = false;
      console.error('Error fetching saved languages:', error);
    });
  }

}
