<div class="translator-card">
  <div class="text-grid-container">
    <form #textTranslateForm="ngForm" (ngSubmit)="onSubmit()">
      <app-language-selector
        [cities]="cities"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        (sourceLangChange)="sourceLang = $event"
        (targetLangChange)="targetLang = $event"
        (switchValues)="switchValues()" class="grid-row">
      </app-language-selector>

      <div class="grid-row">
        <div>
          <div class="form-group">
            <br>
            <div class="text-area-wrap">
              <scale-textarea
                id="input"
                name="input"
                label=""
                class="outline-none"
                max-length="50000"
                counter
                rows="10"
                columns="10"
                resize="none"
                ngDefaultControl
                [(ngModel)]="inputValue"
                #input="ngModel"
                aria-required="true"
                required
                [invalid]="textareaDirty && input.invalid">
              </scale-textarea>
              <div *ngIf="textareaDirty && input.invalid" class="error-message">
                Source text is required.
              </div>
              <scale-icon-action-close *ngIf="inputValue" accessibility-title="Clear source text" selected class="clear-text" (click)="onReset()" />
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <div class="form-group">
            <br>
            <div class="text-area-overlay">
              <div class="copy-text">
                <scale-textarea
                  id="output"
                  name="output"
                  label=""
                  class="form-control"
                  rows="10"
                  columns="10"
                  resize="none"
                  [value]="translatedText"
                  ngDefaultControl
                  [(ngModel)]="outputValue"
                  readonly
                  #output="ngModel">
                </scale-textarea>
                <span (click)="copyToClipBoard()" class="overlay">
                  <scale-icon-action-copy-paste class="copy-icon" accessibility-title="copy to clipboard"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-row-button">
        <div class="translate-button">
          <scale-button type="submit">
            <app-loading-spinner></app-loading-spinner>Translate
          </scale-button>
        </div>
      </div>
    </form>
  </div>
</div>
